import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLocationAction } from "../redux/themeDucks";
import { getListFilters} from "../redux/propertiesDucks";
import { getDevelopmentsAction} from "../redux/developmentsDucks";
import { getActionsAction } from '../redux/actionsDucks';
import {getActionByPage, getActionByType} from '../functions/functions_actions'
import { getFiltersNoveltiesAction, getNoveltiesAction } from "../redux/noveltiesDucks";


function Header(){
    const dispatch = useDispatch();
    const curLocation = useLocation()
    const settings = useSelector((store) => store.settings);
    const theme = useSelector((store) => store.theme);
    const [open, setOpen] = useState(false);
    const developments = useSelector(store => store.developments.developments).data
    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')
    const actions = useSelector(store => store.actions.actions)
    const data = useSelector((store) => store.novelties.novelties);

    
    
    useEffect(() => {
        const checkLocation = () => {
            dispatch(getListFilters());
            dispatch(getDevelopmentsAction())
      };
      checkLocation();
      dispatch(changeLocationAction(curLocation.pathname.split("/")[1]));
    },[curLocation])
    
    useEffect(() => {
        dispatch(getActionsAction())
        data.length === 0 &&(
          dispatch(getNoveltiesAction())
        //   dispatch(getFiltersNoveltiesAction())
        )
    },[])  
    
    const convertPathname = () =>{
        if (pathname.includes('propiedad')) {
            return 'ficha de la propiedad'
        }
        if(pathname.toLowerCase().includes('emprendimientos') && pathname.length > 16 ){
            return 'ficha de emprendimiento'
        }
        switch (pathname) {
            case "":
                return 'inicio'
            case "contacto":
                return 'contacto'
            case "nosotros":
                return 'nosotros'   
            case "ventas":
                return 'resultados venta'
            case "alquileres":
                return 'resultados alquiler'
            case "alquileres-temporario":
                return 'resultados alquiler temporario'
            case "emprendimientos":
                return 'emprendimientos'
            case "tasaciones":
                return 'tasaciones'
            default:
            break;
        }
    }
    
    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),convertPathname())
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),convertPathname())
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),convertPathname())

    
    return developments ? (
        <header className={
            (slider_action?.length > 0 || image_action?.length > 0 || video_action?.length > 0 ? "withFeature " : "") +
            (window.location.pathname.split("/")[1] == "emprendimientos" || theme.location == 'loteo' ? ''  : 
            theme.location == '' ? 'home-nav' :
            theme.location == 'ventas' || theme.location == 'alquileres' || theme.location == 'tasaciones' || theme.location=='favoritos' ? 'results-nav' : 
            theme.location == 'contacto' ?  'contact-nav' : theme.location == 'novedades' ?  'novelties-nav' :
             '')}>
            <div className="container-fluid">
                <nav  className="d-flex justify-content-between align-items-lg-start align-items-center">

                    <Link onClick={() => dispatch(changeLocationAction(''))} to={settings.base_url}>
                        <img src={settings.base_url + "assets/images/logo-header.svg"} alt="" className="logo d-block d-lg-none" />
                        {theme.location == '' ?
                        <img src={settings.base_url + "assets/images/logo-header.svg"} alt="" className="logo d-none d-lg-block" /> :
                        slider_action?.length > 0 || image_action?.length > 0 || video_action?.length > 0  ? 
                        // <img src={settings.base_url + "assets/images/logo-header.svg"} alt="" className="logo d-none d-lg-block" /> 
                        <img src={settings.base_url + "assets/images/logo-header-dark.svg"} alt="" className="logo d-none d-lg-block" /> :
                        theme.location == 'emprendimientos' || theme.location=="loteo" || theme.location == 'propiedad' ||  window.location.pathname.split("/")[5] == "emprendimientos" ?
                        <img src={settings.base_url + "assets/images/logo-header.svg"} alt="" className="logo d-none d-lg-block" /> :
                        theme.location == 'nosotros'  ? 
                        <img src={settings.base_url + "assets/images/logo-header-white.svg"} alt="" className="logo d-none d-lg-block" /> :
                        <img src={settings.base_url + "assets/images/logo-header-dark.svg"} alt="" className="logo d-none d-lg-block" /> 
                        }
                        

                    </Link>

                    <div className="d-flex d-lg-none align-items-center justify-content-end">
                        <div className="icons fav-icon me-3">
                            <Link to={settings.base_url + "favoritos"} onClick={() => dispatch(changeLocationAction("favoritos"), setOpen(false),$("html").removeClass("overflow-hidden-mobile"))} className=""><i className={'icon-solid-like ' + (theme.location == 'favoritos' ? '' : 'notActived')}></i></Link>
                        </div>
                        <button onClick={() => setOpen(!open) + $("html").toggleClass("overflow-hidden-mobile")} className={'menu-toggle d-block d-lg-none '+ (open ? 'open' : '')}>
                            <i></i><i></i><i></i>
                        </button>
                    </div>
                    <ul className={'d-lg-inline-flex ' + (open ? 'open' : '' )}>
                        <li className={theme.location == '' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction('') , setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url}>HOME</Link></li>
                        <li className={theme.location == 'ventas' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction("ventas"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "ventas"}>VENTAS</Link></li>
                        <li className={theme.location == 'alquileres' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction("alquileres"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "alquileres"}>ALQUILERES</Link></li>
                        <li className={(theme.location == 'emprendimientos' ? 'active' : '') + (developments.meta.total_count > 0 ? '' : 'd-none')}><Link onClick={() => dispatch(changeLocationAction("emprendimientos"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "emprendimientos"} href="#">EMPRENDIMIENTOS</Link></li>
                        <li className={theme.location == 'tasaciones' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction("tasaciones"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "tasaciones"} href="#">TASACIONES</Link></li>
                        <li className={theme.location == 'nosotros' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction("nosotros"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "nosotros"} href="#">NOSOTROS</Link></li>
                        <li className={theme.location == 'contacto' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction("contacto"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "contacto"}  href="#">CONTACTO</Link></li>
                        {data?.length > 0 && 
                            <li className={theme.location == 'novedades' ? 'active' : ''}><Link onClick={() => dispatch(changeLocationAction("novedades"), setOpen(!open), $("html").toggleClass("overflow-hidden-mobile"))}to={settings.base_url + "novedades"}  href="#">NOVEDADES</Link></li>
                        }
                        <div className="social-header">
                            <div className="icons">
                               <a href={settings.social.facebook} target="_blank"><i className="icon-facebook"></i></a>
                            </div>
                            <div className="icons">
                                <a href={settings.social.instagram} target="_blank"><i className="icon-instagram"></i></a>

                            </div>
                            <div className="icons">
                                <a href={settings.social.twitter} target="_blank"><i className="icon-twitter"></i></a>
                            </div>
                        </div>
                        <div className="social-header d-none d-lg-block">
                            <div className="icons fav-icon ms-xl-4 ms-lg-1">
                                <Link to={settings.base_url + "favoritos"} onClick={() => dispatch(changeLocationAction("favoritos"))} className=""><i className={'icon-solid-like ' + (theme.location == 'favoritos' ? '' : 'notActived')}></i></Link>
                            </div>
                        </div>
                    </ul>
                </nav>
            </div>
        </header>

    ):''
}

export default Header