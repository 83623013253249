import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import SimpleReactLightbox from 'simple-react-lightbox';
import './assets/styles/App.scss';
import './../node_modules/animate.css/animate.css';


import {BrowserRouter as Router,Switch,withRouter,Route} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
