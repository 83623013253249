import { Link } from "react-router-dom";
import {useState, useEffect} from 'react'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
// import DateTimeInput from '../../../Globals/DateTimeInput'

import {getCompleteName, getDateTime, getTextShort, orderNovelties} from '../../functions/functions_novelties'
import { getFeatured } from "../../functions/functions_dev";

import {useDispatch, useSelector} from 'react-redux'

import {getNoveltieAction,getNoveltiesAction,clearNoveltyAction} from '../../redux/noveltiesDucks'
import Breadcrumbs from "../breadcrumbs";



const Details = () => {
    const settings = useSelector((store) => store.settings);
    const novelty = useSelector(state => state.novelties.novelty)
    const data = useSelector(state => state.novelties.novelties)
    const loading = useSelector(state => state.novelties.loading)

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [content, setContent] = useState('');
  
    const dispatch = useDispatch()

    
    useEffect(() => {
      if(novelty){
        if(novelty.content){
          const blocksFromHtml = htmlToDraft(novelty.content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setEditorState(EditorState.createWithContent(contentState))
        }
      }
    },[novelty])

    const getThree = (data) => {
      // Agarro los primeros 3 activos por position
      return data.filter((item,index) => item.active.toString() === "true" || item.active.toString() === "1").slice(0,3)
    }

    const getFeatured = (novelties) => {
      if(novelties){
        return novelties.filter(item => item.featured === 'true' || item.featured === '1' || item.featured === true)
      }
    }

    return !loading && novelty ?(
        <section class="blog-details">
        <div class="container-fluid">
          <div class="row">
            <div class="col-primary col-md-8">
              <div className="col-12">
                <Breadcrumbs props={[
                  {name:'Home',route:settings.base_url,location:''},
                  {name:'Novedades',route:   "/novedades",location:''},
                  { name: novelty.title && getTextShort(novelty.title,40), route:   "", location: ""},
                ]}/>
              </div>
              <article class="post">
                <div class="post-header col-12 col-lg-11">
                    <div class="time">{novelty?.date_published ? getDateTime(novelty.date_published,true) : 'Cargando..'}</div>
                    <h1 className="mt-lg-4 mt-4">{novelty.title ? novelty.title : 'Cargando..'}</h1>
                    <h3 className="subtitle mt-4 mb-lg-5 mb-5">{getTextShort(novelty.headline,500)}</h3>
                  
                    <div class="blog-meta">
                        <div class="author">
                          publicado por <a>{getCompleteName(novelty)}</a>
                        </div>
                    </div>
                    { novelty?.image 
                      ? (<div className="banner-container mt-4">
                              <img className="banner-image" src={novelty?.image} alt={novelty.title}/>
                              <div className="banner-caption">{novelty?.footer}</div>
                          </div>)
                      : null }
                </div>
                <div className="col-12 col-lg-10 div_body_novelty mt-lg-5 mt-4">
                  <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    // onEditorStateChange={this.onEditorStateChange}
                    disabled={true}
                  />
                </div>
                <div className="div_footer_novelty col-12 col-lg-8">
                  <div class="div_pills d-flex flex-wrap">
                    {
                      novelty ?
                      novelty.tags && novelty.tags.map((tag, index) => (
                        <span className="pill" style={{marginRight:"10px"}}>{tag.name}</span>
                      ))
                      :
                      'Cargando..'
                    }
                  </div>
                  <div className="goBack col-12 mt-5 text-center text-lg-start">
                    <Link className="btn-back" to={settings.base_url + "novedades"}>Volver a novedades</Link>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-secondary col-md-4">
                    <div className="container-fluid">
                      <div className="row text-center">
                        <h2 class="widget-title">Novedades destacadas</h2>  
                        <div className="col-12">
                        {
                            data ? 
                            getFeatured(data).length > 0 ? 
                              // (getFeatured(data).sort(function(a, b){return b.id - a.id})).map((novelty, index) => (     
                                orderNovelties(getFeatured(data)).map((novelty, index) => (                               
                                  <article class="novelty-card mt-4">
                                    {/* {console.log(getFeatured(data))} */}
                                      <div style={{backgroundImage: "url(" + novelty.image + ")"}} className="img-novelty"></div>
                                      <div className="div-content d-flex flex-column p-4 text-start">
                                          <div className="div_texto">
                                              <span className="date">{getDateTime(novelty.date_published)}</span>
                                              <h2 className="title">
                                                  {novelty.title}
                                              </h2>
                                              <p className="description mt-5">
                                              {novelty.headline}
                                              </p>
                                          </div>
                                          <div className="div_button col-12 text-center mt-auto d-lg-flex justify-content-center">
                                              <Link onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(novelty.id))} to={"/novedades/" + novelty.id + "/" + novelty.slug} class=" btn btn-blue mt-3 mt-lg-0 ">Ver Más <span className="span-more"><span className="first-i">+</span></span></Link>
                                          </div>
                                      </div>
                                      <span className="by-name">
                                          por {getCompleteName(novelty)}
                                      </span>
                                </article>
                              )):
                              <span style={{textAlign:"center",display:"block"}}>  No hay novedades destacadas.</span>
                          :''}
                        </div>
                      </div>
                    </div>



              {/* <div class="widget widget_recent_post">
                {
                  data ? 
                  getFeatured(data).length > 0 ? 
                  (getFeatured(data).sort(function(a, b){return b.id - a.id})).map((novelty, index) => (
                            <article class="recent-post">
                              <div class="recent-post-thumbnail">
                                <Link onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(novelty.id))}  to={"/novedades/" + novelty.id + "/" + novelty.slug}><img alt="" src={novelty.image} class="wp-post-image" /></Link>
                              </div>
                              <div class="recent-post-body">
                                <h4 class="recent-post-title">
                                  <Link onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(novelty.id))}  to={"/novedades/" + novelty.id + "/" + novelty.slug}>{novelty.title}</Link>
                                </h4>
                                <div class="recent-post-time">{getDateTime(novelty.date_published)}</div>
                              </div>
                            </article>
                    )):
                    <span style={{textAlign:"center",display:"block"}}>  No hay novedades destacadas.</span>
                :''}
              </div> */}
              <div class="widget">
              </div>
            </div>
          </div>
        </div>
      </section>
    )  :
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Details;