import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function Footer() {
  const [offset, setOffset] = useState(0);
  const [coordenadas, setCoordenadas] = useState(4000);
  const settings = useSelector((store) => store.settings);
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
      setCoordenadas($("footer").offset().top);
    };
  }, []);
  function scrollTop() {
    $("html, body").scrollTop(0);
    return true;
  }
  const getCurrentYear = () => {
    const date = new Date().getFullYear();
    return date;
  };
  return (
    <div onScroll={() => console.log("HOLA")}>
      <footer className="text-center text-lg-start">
        <div class="fixed-bar position-fixed">
          {/* <a href={'https://web.whatsapp.com/send?text=¡Hola!&amp;phone=+5493644571181'} target="_blank" class="icon-whatsapp contact-btn d-none d-lg-flex"></a> */}
          <a
            href={
              "https://api.whatsapp.com/send?phone=" +
              settings.contact.whatsapp.link
            }
            target="_blank"
            class="icon-whatsapp contact-btn d-flex"
          ></a>
          <div
            onClick={() => scrollTop()}
            className={
              "btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn " +
              (offset > coordenadas - 2500 && offset > 300 ? "active" : "")
            }
          >
            <span className="icon-arrow-right"></span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <img
                src={settings.base_url + "assets/images/logo-header.svg"}
                alt=""
              />

              <div className="mt-5">
                <h5 className="title-btn">ACCESO CLIENTES</h5>
                <a
                  href="https://gexion.com.ar/#/home"
                  className="btn btn-access d-flex align-items-center justify-content-center"
                >
                  <i className="icon-key"></i>
                  INGRESAR
                </a>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="content">
                <h5>OFICINA</h5>
                <p>
                  DI: Moreno 841, Saenz Peña, Chaco <br />
                  CP: H3700HPQ
                </p>
              </div>
              <div className="content">
                <h5>CONTACTO</h5>
                <p>
                  TE:{" "}
                  {settings.contact.phone[0] +
                    " / " +
                    settings.contact.phone[1]}
                  <br />
                  MA:{" "}
                  <a href={"mailto:" + settings.contact.mail}>
                    {settings.contact.mail}{" "}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="content">
                <h5>VENTAS Y TASACIONES</h5>
                <p>
                  Ramiro Trabalon Fanelli <br />
                  <a href="mailto:ramiro@fanellitrabalon.com">
                    ramiro@fanellitrabalon.com
                  </a>{" "}
                  <br />
                  WA: 3644 - 305038
                </p>
              </div>
              <div className="content">
                <h5>ADMINISTRACIÓN</h5>
                <p>
                  Maria Delfina Trabalon Fanelli
                  <br />
                  <a href="mailto:delfina@fanellitrabalon.com">
                    delfina@fanellitrabalon.com
                  </a>{" "}
                  <br />
                  WA: 3644 - 230812
                </p>
              </div>
              <div className="content">
                <h5>ALQUILERES</h5>
                <p>
                  Yuliana Cordoba <br />
                  <a href="mailto:yuliana@fanellitrabalon.com">
                    yuliana@fanellitrabalon.com
                  </a>{" "}
                  <br />
                  WA: 3644 - 571181
                </p>
              </div>
            </div>
            <div className="d-none btn-filters"></div>
            <div className="col-lg-3">
              <div className="content">
                <h5>SEGUINOS EN</h5>
                <span className="d-block">
                  INSTAGRAM /{" "}
                  <a target="_blank" href={settings.social.instagram}>
                    @fanellitrabalon
                  </a>
                </span>
                <span className="d-block">
                  FACEBOOK /{" "}
                  <a target="_blank" href={settings.social.facebook}>
                    @fanellitrabalon
                  </a>
                </span>
                <span className="d-block">
                  TWITTER /{" "}
                  <a target="_blank" href={settings.social.twitter}>
                    @fanellitrabalon
                  </a>
                </span>
              </div>
            </div>
            {/* <div className="col-12 d-lg-flex justify-content-between">
                            <p className="copy mt-5 mt-lg-0">Plataforma MediaCore®  by <a href="https://mediahaus.com.ar"> MediaHaus </a> 2021. Todos los derechos reservados.</p>
                            <img className="qr d-none d-lg-block" src={settings.base_url +"assets/images/qr.png"} alt="" />
                        </div> */}
            <div className="d-flex justify-content-center justify-content-lg-start align-items-start mt-5 mt-lg-0">
              <p className="copy">
                Design by{" "}
                <a
                  target="_blank"
                  className="mh"
                  href="https://mediahaus.com.ar"
                >
                  MediaHaus
                </a>{" "}
                - Powered by{" "}
                <a target="_blank" className="mc" href="https://mediacore.app/">
                  {" "}
                  MediaCore
                </a>{" "}
                <br /> Todos los derechos reservados &reg; {getCurrentYear()}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
