export const getActionByPage = (actions,page) => {
    if (!page) return []
    if (!actions) return []
    return actions.filter(action => action.section.toLowerCase() === page.toLowerCase())
}

export const getActionByType = (actions,type) => {
    return actions.filter(action => action.type.toLowerCase() === type.toLowerCase())
}

export const convertStringToTime = (string) => {
    const date = string.split("T")[0]
    const splitDate = date.split("-")
    const formateDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0]
    return formateDate;

}

export const removeExpiratedActions = (actions) => {
    const today = new Date()
    const filtered = []
    for (let index = 0; index < actions.length; index++) {
        const element = actions[index];
        if(new Date(element.date_end) > today || element.date_end === null ){
            filtered.push(element)
        }
    }
    return filtered;
}